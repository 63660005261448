import GenericError from './GenericError'
import { ErrorComponentProps } from '@/types'
import { LayoutStack } from '@betterplace/design-system/server'
import { report } from '@/errorReporting'
import { useEffect } from 'react'

function Error({ error, reset }: ErrorComponentProps) {
  useEffect(() => {
    report(error, { tags: 'client' }) // this is a generic error in production
  }, [error])

  return (
    <LayoutStack space="400">
      <GenericError reset={reset} />
    </LayoutStack>
  )
}

Error.displayName = 'Error'

// eslint-disable-next-line import/no-unused-modules
export default Error
