import ErrorXxx from '@/assets/errorXxx.svg'
import Image, { StaticImageData } from 'next/image'
import styles from '../Error.module.css'
import { Button, Heading, LayoutStack } from '@betterplace/design-system/server'
import { useTranslations } from 'next-intl'

function GenericError({ reset }: { reset: () => void }) {
  const t = useTranslations('nextjs.errors')

  // eslint-disable-next-line no-constant-condition
  // if (false) return NotAuthorized

  return (
    <>
      <LayoutStack space="200" className={styles.centeredContainer}>
        <Heading level="h100" style={{ font: 'var(--betterplace-typography-heading-md)' }}>
          {t('messages.status.xxx.title')}
        </Heading>
        <p>{t('messages.status.xxx.reasoning')}</p>
      </LayoutStack>
      <div className={styles.imageContainer}>
        <Image src={ErrorXxx as StaticImageData} alt="" fill priority />
      </div>
      <LayoutStack space="200" className={styles.centeredContainer}>
        <Heading level="h200">{t('messages.status.xxx.todo_headline')}</Heading>
        <div>
          <Button onClick={reset}>{t('messages.try_again')}</Button>
        </div>
      </LayoutStack>
    </>
  )
}

GenericError.displayName = 'GenericError'

// eslint-disable-next-line import/no-unused-modules
export default GenericError
